import API from '.';

export const getScans = ({ orgId }) => API.get(`/org/${orgId}/scan`);

export const getSingleScan = ({ orgId, scanId }) => API.get(`/org/${orgId}/scan/${scanId}`);

export const createScan = ({ orgId, payload }) => API.post(`/org/${orgId}/scan`, payload);

export const deleteScan = ({ orgId, scanId }) => API.delete(`/org/${orgId}/scan/${scanId}`);

export const runScan = ({ orgId, scanId }) => API.post(`/org/${orgId}/scan/${scanId}/run`, {});

export const listReports = ({ orgId, scanId }) => API.get(`/org/${orgId}/scan/${scanId}/report`);

export const getReportDetail = ({ orgId, scanId, reportId }) =>
  API.get(`/org/${orgId}/scan/${scanId}/report/${reportId}`);
