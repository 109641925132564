import { createSlice } from '@reduxjs/toolkit';

import { getScanList, createScan, getScanReports, getScanDetail, getReportDetail, deleteScan } from '../thunks/scanner';

const initialState = {
  scans: [],
};

const slice = createSlice({
  name: 'scanner',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScanList.fulfilled, (state, { payload }) => {
      state.scans = payload || [];
    });
    builder.addCase(getScanDetail.fulfilled, (state, { payload }) => {
      const { scans } = state;
      const index = scans.findIndex(({ id }) => id === payload.id);
      if (index >= 0) scans[index] = payload;
      else scans.push(payload);
      state.scans = [...scans];
    });
    builder.addCase(createScan.fulfilled, (state, { payload }) => {
      state.scans = [...state.scans, payload];
    });
    builder.addCase(deleteScan.fulfilled, (state, { payload }) => {
      state.scans = state.scans.filter(({ id }) => id !== payload.scanId);
    });
    builder.addCase(getScanReports.fulfilled, (state, { payload }) => {
      const { scans } = state;
      const index = scans.findIndex(({ id }) => id === payload.id);
      if (index >= 0) scans[index] = payload;
      else if (payload.id) scans.push(payload);
      state.scans = [...scans];
    });
    builder.addCase(getReportDetail.fulfilled, (state, { payload }) => {
      const { scans } = state;
      const index = scans.findIndex(({ id }) => id === payload.id);
      if (index >= 0) scans[index] = payload;
      else if (payload.id) scans.push(payload);
      state.scans = [...scans];
    });
  },
});

export default slice.reducer;
