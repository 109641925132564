import { useDispatch as useReduxDispatch, useSelector as useReduxSelector } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';

import rootReducer from './rootReducer';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => {
    const deafultMiddleWare = getDefaultMiddleware();
    if (process.env.NODE_ENV !== `production`) {
      deafultMiddleWare.push(logger);
    }
    return deafultMiddleWare;
  },
  devTools: true,
});

export const useSelector = useReduxSelector;

export const useDispatch = () => useReduxDispatch();

export default store;
