import { createAsyncThunk } from '@reduxjs/toolkit';
import { SCAN_STATUS } from 'src/constants';
import * as API from '../api/scanner';
import { errorHandler } from './utils';

export const getScanList = createAsyncThunk('GET/Scans', async (param, { rejectWithValue }) => {
  try {
    return await API.getScans(param);
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});

export const getScanDetail = createAsyncThunk('GET/Scan', async (param, { rejectWithValue }) => {
  try {
    return await API.getSingleScan(param);
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});

export const createScan = createAsyncThunk('POST/Scan', async (param, { rejectWithValue }) => {
  try {
    const scan = await API.createScan(param);
    scan.status = SCAN_STATUS.QUEUED;
    return scan;
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});

export const deleteScan = createAsyncThunk('DELETE/Scan', async (param, { rejectWithValue }) => {
  try {
    await API.deleteScan(param);
    return param;
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});

export const runScan = createAsyncThunk('POST/Run', async (param, { rejectWithValue }) => {
  try {
    return await API.runScan(param);
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});

export const getScanReports = createAsyncThunk('GET/Reports', async (param, { rejectWithValue }) => {
  try {
    const scan = await API.getSingleScan(param);
    const reports = await API.listReports(param);
    return { ...scan, reports };
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});

export const getReportDetail = createAsyncThunk('GET/Report', async (param, { rejectWithValue }) => {
  try {
    const scan = await API.getSingleScan(param);
    const report = await API.getReportDetail(param);
    return { ...scan, reports: [report] };
  } catch (err) {
    return rejectWithValue(errorHandler(err));
  }
});
