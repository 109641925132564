import ReceiptTwoToneIcon from '@mui/icons-material/ReceiptTwoTone';

const menuItems = [
  {
    heading: 'Scanner',
    items: [
      {
        name: 'Scan Profiles',
        icon: ReceiptTwoToneIcon,
        link: '/scanner/scans',
      },
    ],
  },
  {
    heading: 'Partner',
    items: [],
  },
];

export default menuItems;
