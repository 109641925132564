export const SCAN_NETWORK_TYPE = {
  CLOUD: 'Cloud',
  SAAS: 'SaaS',
  ON_PREMISE: 'On-Premise',
};

export const SCAN_STATUS = {
  QUEUED: 'queued',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
};

export const REPORT_STATUS = {
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
};

export const VUL_SEVERITY = {
  LOW: 'low',
  MEDIUM: 'medium',
  HIGH: 'high',
  CRITICAL: 'critical',
};
