import axios from 'axios';

const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosInt.interceptors.response.use(
  (response) => response.data,
  (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem('accessToken');
      window.location.replace('/auth/login');
    }
    return Promise.reject(error.response);
  },
);

export default axiosInt;
