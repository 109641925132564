/* eslint-disable no-bitwise */
import jwt from 'jsonwebtoken';
import API from 'src/redux/api';

export const JWT_SECRET = 'jwt-secret-key';
export const JWT_EXPIRES_IN = 3600 * 24 * 2;

export const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    API.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete API.defaults.headers.common.Authorization;
  }
};

export const decodeToken = (token) => {
  const decodedToken = jwt.decode(token, { json: true });
  return decodedToken;
};

export const sign = (payload, privateKey, header) => {
  const now = new Date();
  header.expiresIn = new Date(now.getTime() + header.expiresIn);
  const encodedHeader = btoa(JSON.stringify(header));
  const encodedPayload = btoa(JSON.stringify(payload));
  const signature = btoa(
    Array.from(encodedPayload)
      .map((item, key) => String.fromCharCode(item.charCodeAt(0) ^ privateKey[key % privateKey.length].charCodeAt(0)))
      .join(''),
  );

  return `${encodedHeader}.${encodedPayload}.${signature}`;
};

export const decode = (token) => {
  const [encodedHeader, encodedPayload, signature] = token.split('.');
  const header = JSON.parse(atob(encodedHeader));
  const payload = JSON.parse(atob(encodedPayload));
  const now = new Date();

  if (now < header.expiresIn) {
    throw new Error('Expired token');
  }

  const verifiedSignature = btoa(
    Array.from(encodedPayload)
      .map((item, key) => String.fromCharCode(item.charCodeAt(0) ^ JWT_SECRET[key % JWT_SECRET.length].charCodeAt(0)))
      .join(''),
  );

  if (verifiedSignature !== signature) {
    throw new Error('Invalid signature');
  }

  return payload;
};

export const verify = (token) => {
  const { exp } = decodeToken(token);
  const now = new Date();

  if (now > new Date(exp * 1000)) {
    throw new Error('The token is expired!');
  }

  return true;
};
