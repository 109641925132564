import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';

import Guest from 'src/authLevel/Guest';
import Protected from 'src/authLevel/Protected';
import Authenticated from 'src/authLevel/Authenticated';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

const Login = Loader(lazy(() => import('src/content/auth/Login')));
const Signup = Loader(lazy(() => import('src/content/auth/Register')));
const EmailConfirm = Loader(lazy(() => import('src/content/auth/Register/EmailConfirm')));
const ProfileStepper = Loader(lazy(() => import('src/content/auth/Register/ProfileForms')));

const Scans = Loader(lazy(() => import('src/content/scanner/Scans')));
const Reports = Loader(lazy(() => import('src/content/scanner/Reports')));
const ReportDetail = Loader(lazy(() => import('src/content/scanner/ReportDetail')));

const router = [
  {
    path: 'auth',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <Login />
          </Guest>
        ),
      },
      {
        path: 'signup',
        children: [
          {
            path: '/',
            element: (
              <Guest>
                <Signup />
              </Guest>
            ),
          },
          {
            path: 'email-confirmation',
            element: <EmailConfirm />,
          },
        ],
      },
    ],
  },
  {
    path: 'signup/profile',
    element: (
      <Protected>
        <ProfileStepper />
      </Protected>
    ),
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: <Navigate to="auth/login" replace />,
      },
    ],
  },

  {
    path: 'scanner',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/scans',
        children: [
          {
            path: '/',
            element: <Scans />,
          },
          {
            path: ':scanId',
            element: <Scans />,
          },
        ],
      },
      {
        path: '/scans/:scanId/reports',
        children: [
          {
            path: '/',
            element: <Reports />,
          },
          {
            path: ':reportId',
            element: <ReportDetail />,
          },
        ],
      },
    ],
  },
];

export default router;
