import { createContext, useEffect, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import * as AUTH_API from 'src/redux/api/auth';
import * as USER_API from 'src/redux/api/user';
import { verify, setSession, decodeToken } from 'src/utils/jwt';

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  org: null,
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, org } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      org,
    };
  },
  LOGIN: (state, { payload }) => ({
    ...state,
    isAuthenticated: true,
    user: payload.user,
    org: payload.org,
  }),
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    org: null,
  }),
  SIGNUP: (state, { payload }) => ({
    ...state,
    isAuthenticated: true,
    user: payload.user,
  }),
  REGISTER: (state, { payload }) => ({
    ...state,
    isAuthenticated: true,
    user: payload.user,
    org: payload.org,
  }),
  SET_AUTHENTICATED: (state, { payload }) => ({
    ...state,
    isAuthenticated: payload,
  }),
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  signup: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setAuthenticated: () => Promise.resolve(),
});

export const AuthProvider = (props) => {
  const { children } = props;
  const location = useLocation();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken && verify(accessToken)) {
          if (location.pathname.includes('/auth')) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
                org: null,
              },
            });
          } else {
            setSession(accessToken);
            const { sub: email, type } = decodeToken(accessToken);
            if (type === 'activation') {
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user: { email },
                },
              });
            } else {
              const user = await USER_API.getUser();
              const org = await USER_API.getOrganization();
              dispatch({
                type: 'INITIALIZE',
                payload: {
                  isAuthenticated: true,
                  user,
                  org: org[0],
                },
              });
            }
          }
        } else {
          setSession();
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              org: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            org: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password) => {
    const { token } = await AUTH_API.login({ email, password });
    setSession(token);
    const user = await USER_API.getUser();
    const org = await USER_API.getOrganization();

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        org: org[0],
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  const signup = async ({ email }) => {
    const { token } = await AUTH_API.signup({ email });
    setSession(token);
    dispatch({ type: 'SIGNUP', payload: { user: { email } } });
  };

  const register = async (payload) => {
    const { token } = await AUTH_API.activate(payload);
    setSession(token);
    const user = await USER_API.getUser();
    const org = await USER_API.getOrganization();
    dispatch({ type: 'REGISTER', payload: { user, org: org[0] } });
  };

  const setAuthenticated = async (value) => {
    dispatch({ type: 'SET_AUTHENTICATED', payload: value });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        signup,
        register,
        setAuthenticated,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
