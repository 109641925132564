import { Box, Fab, CircularProgress, colors, styled } from '@mui/material';

import LogoSMSVG from 'src/assets/images/logo_acreto_sm.svg';

const Loader = styled(Box)(
  () => `
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
`,
);

const ImageWrapper = styled('img')(
  () => `
    height: 30px;
    width: auto;
  `,
);

const GloablLoader = () => (
  <Loader>
    <Box sx={{ position: 'relative' }}>
      <Fab aria-label="save" color="secondary">
        <ImageWrapper src={LogoSMSVG} />
      </Fab>
      <CircularProgress
        size={68}
        sx={{
          color: colors.green[500],
          position: 'absolute',
          top: -6,
          left: -6,
          zIndex: 1,
        }}
      />
    </Box>
  </Loader>
);

export default GloablLoader;
