import { useRoutes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { CssBaseline } from '@mui/material';

import useAuth from 'src/hooks/useAuth';
import router from 'src/router';

import GloablLoader from './components/GlobalLoader';
import ThemeProvider from './theme/ThemeProvider';

function App() {
  const content = useRoutes(router);
  const auth = useAuth();

  return (
    <ThemeProvider>
      <SnackbarProvider
        maxSnack={6}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        autoHideDuration={3000}
      >
        <CssBaseline />
        {auth.isInitialized ? content : <GloablLoader />}
      </SnackbarProvider>
    </ThemeProvider>
  );
}
export default App;
