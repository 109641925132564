import { Link } from 'react-router-dom';
import { styled } from '@mui/material';

import LogoSVG from 'src/assets/images/logo_acreto.svg';

const ImageWrapper = styled('img')(
  () => `
    width: 144px;
    margin: 0 auto;
`,
);

const LogoWrapper = styled(Link)(
  ({ theme }) => `
    padding: ${theme.spacing(0, 1, 0, 0)};
    display: flex;
    text-decoration: none;
    font-weight: ${theme.typography.fontWeightBold};
`,
);

const Logo = () => (
  <LogoWrapper to="/scanner/scans">
    <ImageWrapper src={LogoSVG} />
  </LogoWrapper>
);

export default Logo;
